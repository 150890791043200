import { css } from "@emotion/react";

export const BREAKPOINTS = {
  xs: 420,
  sm: 576,
  md: 768,
  lg: 900,
  xl: 1200,
  xxl: 1536,
};

export const bp = Object.keys(BREAKPOINTS)
.map((key) => [ key, BREAKPOINTS[key] ])
.reduce((prev, [ key, breakpoint ]) => {
  prev[key] = `@media (min-width: ${ breakpoint }px)`;
  return prev;
}, {});