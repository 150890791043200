import React from "react";
import { BlackGrungeLeft, BlackGrungeRight, OrangeCircles, Wrapper } from "./styled";
import { Text } from "@chakra-ui/react";
import { css } from "@emotion/react";

export function Header() {
  return <Wrapper>
    <OrangeCircles/>
    <BlackGrungeLeft/>
    <BlackGrungeRight/>

    <Text
      fontSize={ [ "xs", "sm", "sm", "sm", "lg", "xl" ] }
      fontWeight={ "bold" }
      letterSpacing={ [ "0.4em", "0.5em" ] }
      mt={ [ 1, 2, 2, 2, 5 ] }
      ml={ [ 4, 8, 8, 8, 12 ] }
      color={ "#ff5104" }
      zIndex={ "10" }
      position={ "relative" }
    >
      FREELY-GIVING FAUCET
    </Text>
  </Wrapper>;
}