import React from "react";
import { Box, Container, Stack, VStack } from "@chakra-ui/react";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import bg from "../images/bg.jpeg";
import { css } from "@emotion/react";
import { bp } from "../lib/breakpoints";

export function Layout(props) {
  return <VStack
    h="100vh"
    alignItems={ "center" }
    justify={ "space-between" }
    css={ css`
      background: url(${ bg }) no-repeat 30% center;
      background-size: cover;
      overflow: hidden;

      ${ bp.lg } {
        background-position: center;
      }
    ` }
  >
    <Header/>
    <Container
      maxW="1280px"
      h={ "100%" }
      flex={ 1 }
      centerContent
    >
      <Box h="100%" pt={ "14vh" }>
        { props.children }
      </Box>

    </Container>
    <Footer/>
  </VStack>;
}
