import BlackGrungeBottomLeft from "../../images/black-grunge-bottom-left.inline.svg";
import BlackGrungeBottomRight from "../../images/black-grunge-bottom-right.inline.svg";
import BlackCirclesOnBottom from "../../images/black-circles-on-the-bottom.inline.svg";
import styled from "@emotion/styled";
import { bp } from "../../lib/breakpoints";

export const Wrapper = styled.footer`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5%;
  pointer-events: none;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
`;

export const BlackGrungeLeft = styled(BlackGrungeBottomLeft)`
  position: absolute;
  width: 50%;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;

export const BlackGrungeRight = styled(BlackGrungeBottomRight)`
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  width: 100%;

  ${ bp.md } {
    width: 50%;
  }
`;

export const BlackCircles = styled(BlackCirclesOnBottom)`
  position: absolute;
  bottom: -2px;
  right: 0;
  left: 0;
  pointer-events: none;
`;