import BlackGrungeTopLeft from "../../images/black-grunge-top-left.inline.svg";
import OrangeCirclesOnTop from "../../images/orange-circles-on-top.inline.svg";
import BlackGrungeTopRight from "../../images/black-grunge-top-right.inline.svg";
import styled from "@emotion/styled";
import { bp } from "../../lib/breakpoints";

export const Wrapper = styled.header`
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
  pointer-events: none;
  height: 12%;
`;

export const BlackGrungeLeft = styled(BlackGrungeTopLeft)`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 80%;

  ${ bp.md } {
    width: 50%;
  }
`;

export const OrangeCircles = styled(OrangeCirclesOnTop)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

export const BlackGrungeRight = styled(BlackGrungeTopRight)`
  position: absolute;
  width: 20%;
  top: 0;
  right: 0;
  pointer-events: none;
`;