import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { BitKeepConnector } from "bitkeep-connector";



const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42220, 56, 592, 250, 42161, 43114, 336, 137, 1088, 5551, 1666600000, 1285, 10, 9001, 100, 2222, 42262]
});

const walletconnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true
});

const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
  appName: "web3-react-demo"
});

export const bitKeep = new BitKeepConnector({
  supportedChainIds: [1, 3, 4, 5, 42220, 56, 592, 250, 42161, 43114, 336, 137, 1088, 5551, 1666600000, 1285, 10, 9001, 100, 2222, 42262]
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink,
  bitKeep: bitKeep
};
