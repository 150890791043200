import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";

import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";


import App from "./App";
import { Layout } from "./components/Layout";
import { PP } from "./PP"

import "@fontsource/open-sans/800.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/open-sans/400.css";
import "./styles.css";
import theme from "./theme";

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route index element={
        <StrictMode>
          <ChakraProvider theme={theme}>
            <Web3ReactProvider getLibrary={getLibrary}>
              <Layout>
                <App />
              </Layout>
            </Web3ReactProvider>
          </ChakraProvider>
        </StrictMode>} />
      <Route path="pp" element={<PP />} />
    </Routes>
  </BrowserRouter>,
  rootElement,
);
