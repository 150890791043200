/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useState } from "react";
import {
  VStack,
  useDisclosure,
  Button,
  Text,
  HStack,
  Select,
  Input,
  Box, ButtonGroup, Image
} from "@chakra-ui/react";
import SelectWalletModal from "./Modal";
import { useWeb3React } from "@web3-react/core";
import CheckCircleIcon from "../src/images/icon_ok.svg";
import WarningIcon from "../src/images/icon_attention.svg";
import { Tooltip } from "@chakra-ui/react";
import { networkParams } from "./networks";
import { connectors } from "./connectors";
import { toHex, truncateAddress } from "./utils";
import { ethers } from "ethers";
import Orange from "../src/images/backing_orange.svg";
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import styled from "@emotion/styled";
import buttonDisconnect from "../src/images/button_disconnect.png";
import buttonConnect from "../src/images/button_connect.png";
import buttonTexture from "../src/images/button_active.png";
import buttonTextureDisabled from "../src/images/button_not_active.png";
import { bp } from "./lib/breakpoints";

const MotionButton = forwardRef(({ children, disableMotion, ...props }, ref) => {
  return <motion.button
    whileTap={!disableMotion ? { scale: 0.98, boxShadow: "none" } : undefined}
    whileHover={!disableMotion ? { scale: 1.02 } : undefined}
    ref={ref} {...props}>{children}</motion.button>;
});

const textShadow = css`
  text-shadow: 2px 2px 0 rgb(0 0 0 / 81%);
`;

const OrangeStyled = styled(Orange)`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ButtonStyled = styled(MotionButton)`
  padding: 0.5rem;
  min-width: 150px;
  font-weight: 600;
  background: url(${buttonTexture}) no-repeat center;
  background-size: cover;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
  color: white;
`;

const buttonStyledSizes = ["sm", "lg", "lg", "lg", "xl", "2xl"];

const ConnectionButton = styled(MotionButton)`
  padding: 0.5em 1em;
  min-width: 200px;
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
  color: white;

  ${bp.xl} {
    min-width: 250px;
  }

  ${bp.xxl} {
    min-width: 320px;
  }
`;

const connectionButtonSizes = ["md", "xl", "xl", "xl", "xl", "3xl"];

export default function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
  } = useWeb3React();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [network, setNetwork] = useState(Number(5));
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();

  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };

  const handleInput = (e) => {
    const msg = e.target.value;
    setMessage(msg);
  };

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }],
      });
      // const balance = await library.getBalance(account);
      // setZero(balance.isZero())
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]],
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const getUSDC = async () => {

    if (chainId != 5) return;
    if (isZero) return;

    console.log("TEST");
    const txCount = await library.getTransactionCount(account);
    console.log(txCount);

    const tx = {
      from: account,
      to: "0x1ca525cd5cb77db5fa9cbba02a0824e283469dbe",
      value: ethers.utils.parseEther("0"),
      nonce: await library.getTransactionCount(account, "latest"),
      //gasLimit: ethers.utils.hexlify(3000000),
      gasPrice: ethers.utils.hexlify(parseInt(await library.getGasPrice())),
      data: "0x40c10f19000000000000000000000000c2c527c0cacf457746bd31b2a698fe89de2b6d4900000000000000000000000000000000000000000000000000000002540be400",
    };
    const tr = await library.getSigner().sendTransaction(tx);
  };

  const signMessage = async () => {
    if (!library) return;
    try {
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account],
      });
      setSignedMessage(message);
      setSignature(signature);
    } catch (error) {
      setError(error);
    }
  };

  //пока не используется
  const getGAS = async (adr) => {
    if (!library) return;

    // setDeny(true);
    // const response = await fetch("http://64.225.96.156:3001/" + adr);
    // const data = await response.text();
    // console.log(data);
  };
  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature],
      });
      setVerified(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const openFaucet = () => {
    const link = document.createElement("a");
    link.href = "https://goerlifaucet.com/";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getMacClient = () => {
    const link = document.createElement("a");
    link.href = mac;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getWinClient = () => {
    const link = document.createElement("a");
    link.href = win;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getAndroidClient = () => {
    const link = document.createElement("a");
    link.href = "https://play.google.com/store/apps/details?id=io.stablebattle.android.beta";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = () => {
    refreshState();
    deactivate();
  };

  const [win, setWin] = useState("https://download.stablebattle.io/stablebattle-setup-beta-0.2.426.8f892231.exe");
  const [mac, setMac] = useState("https://download.stablebattle.io/stablebattle-setup-beta-0.2.426.8f892231.pkg");
  const [isZero, setZero] = useState(true);
  //не используется
  //const [denyGasButton, setDeny] = useState(false);

  // useEffect(() => {
  //   const macUrl = window.localStorage.getItem("mac");
  //   if (macUrl) setMac("https://drive.google.com/u/0/uc?id=1vEREuvKGxKxI_teLkTFET-0hYtj5CWK6&export=download");
  //   const winUrl = window.localStorage.getItem("win");
  //   if (winUrl) setWin("https://drive.google.com/u/0/uc?id=14m3O_P8gtZ8SgYcThECjt6hDg7w5tZMM&export=download");
  // }, []);

  useEffect(() => {
    if (active) {
      console.log("Try to change Network");
      switchNetwork();
    }
  }, [active, chainId]);

  useEffect(() => {

    const getBalance = async () => {
      const balance = await library.getBalance(account);
      console.log(balance);
      setZero(balance.isZero());
    };

    if (account) {
      getBalance();
    }
  }, [account, chainId]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (chainId === 5) {
        const balance = await library.getBalance(account);
        console.log('Balance ' + balance);
        setZero(balance.isZero());
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [account]);


  return (
    <>
      <VStack justifyContent="flex-start" spacing={2}>
        <HStack
          w={["95vw", "md", "2xl", "2xl", "3xl", "5xl"]}
          marginBottom={["32px", "48px"]}
          alignItems={"center"}
          justifyContent={"center"}
          position={"relative"}
        >
          <OrangeStyled />
          <Text
            margin="0"
            lineHeight="1.15"
            align={"center"}
            fontSize={["1.5em", "1.8em", "2.5em", "2.5em", "2.8em", "3.5em"]}
            letterSpacing={"0.05em"}
            fontWeight="600"
            color={"white"}
            zIndex={10}
            whiteSpace={"nowrap"}
          >
            Let's play StableBattle
          </Text>
        </HStack>

        <HStack>
          {!active ? (
            <ConnectionButton
              onClick={onOpen}
              css={css`
                background: url(${buttonConnect}) no-repeat center;
                background-size: cover;
              ` }
            ><Text
              fontSize={connectionButtonSizes}
              css={textShadow}
            >Connect Wallet</Text></ConnectionButton>
          ) : (
            <ConnectionButton
              onClick={disconnect}
              css={css`
                background: url(${buttonDisconnect}) no-repeat center;
                background-size: cover;
              ` }
            ><Text
              fontSize={connectionButtonSizes}
              css={textShadow}
            >Disconnect</Text></ConnectionButton>
          )}
        </HStack>

        <VStack justifyContent="center" alignItems="center">
          <HStack spacing={1} mb={"24px"} alignItems={"center"}>
            <Text
              fontWeight={"bold"}
              fontSize={["0.8em", "0.8em", "0.9em", "1.1em", "1.2em"]}
            >{`Connection Status: `}
            </Text>
            {active ? (
              <CheckCircleIcon width={"20px"} />
            ) : (
              <WarningIcon width={"20px"} />
            )}
          </HStack>
        </VStack>
        {active && (
          <HStack justifyContent="flex-start" alignItems="flex-start">
            <VStack spacing={0}>

              <VStack spacing={3} mb={3}>
                {isZero && (
                  <ButtonStyled
                    disableMotion={chainId != 5}
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: url(${chainId != 5
                        ? buttonTextureDisabled
                        : buttonTexture}) no-repeat center;
                      background-size: cover;
                      ${(chainId != 5) && "box-shadow: none;"}
                    ` }
                    onClick={() => openFaucet()}
                    isDisabled={chainId != 5}
                  >
                    <Text
                      mr={1}
                      fontSize={buttonStyledSizes}
                      css={textShadow}
                    >{`Get Goerli GAS `}</Text>
                    {/* {chainId != 5 ? (
                     <CheckCircleIcon color="#00a213" width={"10px"} />
                     ) : (
                     <InfoOutlineIcon color="#8e0000" width={"10px"} />
                     )} */ }
                  </ButtonStyled>
                )}

                <ButtonStyled
                  disableMotion={chainId != 5 || isZero}
                  css={css`
                    ${textShadow};
                    background: url(${chainId != 5 || isZero
                      ? buttonTextureDisabled
                      : buttonTexture}) no-repeat center;
                    background-size: cover;
                  ` }
                  onClick={getUSDC}
                  isDisabled={chainId != 5}
                >
                  <Text mr={1} fontSize={buttonStyledSizes}>
                    Mint Goerli USDT
                  </Text>
                </ButtonStyled>
              </VStack>
            </VStack>

          </HStack>
        )}

        <Text>{error ? error.message : null}</Text>
        <HStack justify={"center"} gap={[3]}>

          <ButtonStyled
            css={css`
                ${textShadow};
                height: auto;
                padding: 0.5em 1.5em;
              ` }
            onClick={() => getMacClient()}
            isDisabled={chainId != 5}
          ><Text fontSize={buttonStyledSizes}>
              Download<br />MacOS Client
            </Text>
          </ButtonStyled>


          <ButtonStyled
            css={css`
                ${textShadow};
                height: auto;
                padding: 0.5em 1em;
              ` } onClick={() => getWinClient()} isDisabled={chainId != 5}
          >
            <Text fontSize={buttonStyledSizes}>
              Download<br />Windows Client
            </Text>
          </ButtonStyled>
        </HStack>
        <HStack justify={"center"} gap={[3]}>
          <ButtonStyled
            css={css`
                height: auto;
                padding: 0.5em 1em;
              ` } onClick={() => getAndroidClient()}
          >
            <Image
              src="/play.png"
              alt="PlayWallet Connect Logo"
              width={150}
            />
          </ButtonStyled>
        </HStack>
      </VStack>
      <SelectWalletModal isOpen={isOpen} closeModal={onClose} />
    </>
  );
}
