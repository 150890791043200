import React from "react";
import {
  BlackCircles,
  BlackGrungeLeft,
  BlackGrungeRight,
  Wrapper,
} from "./styled";

export function Footer() {
  return <Wrapper>
    <BlackCircles/>
    <BlackGrungeLeft/>
    <BlackGrungeRight/>
  </Wrapper>;
}